@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply bg-blue-900
    text-white;
  }

  li {
    @apply px-4 cursor-pointer;
  }
  .section {
    @apply max-w-[1000px] 
    mx-auto
    p-4 
    flex 
    flex-col 
    justify-center 
    w-[60%] 
    lg:w-[40%] 
    h-full;
  }
  .section-header {
    @apply text-4xl
    font-bold
    border-b-4;
  }

  .social-icons {
    @apply w-[160px] 
    h-[60px] 
    flex 
    justify-between 
    items-center 
    ml-[-100px] 
    hover:ml-[-10px] 
    duration-300 
    bg-blue-400;
  }

  input {
    @apply bg-white
    text-black
    w-full
    mb-2;
  }
  textarea {
    @apply bg-white
    text-black
    w-full
    h-[100px];
  }
}
